exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-author-tsx": () => import("./../../../src/pages/author.tsx" /* webpackChunkName: "component---src-pages-author-tsx" */),
  "component---src-pages-book-tsx": () => import("./../../../src/pages/book.tsx" /* webpackChunkName: "component---src-pages-book-tsx" */),
  "component---src-pages-chronology-tsx": () => import("./../../../src/pages/chronology.tsx" /* webpackChunkName: "component---src-pages-chronology-tsx" */),
  "component---src-pages-contenders-tsx": () => import("./../../../src/pages/contenders.tsx" /* webpackChunkName: "component---src-pages-contenders-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-miscellany-tsx": () => import("./../../../src/pages/miscellany.tsx" /* webpackChunkName: "component---src-pages-miscellany-tsx" */),
  "component---src-templates-chronology-tsx": () => import("./../../../src/templates/chronology.tsx" /* webpackChunkName: "component---src-templates-chronology-tsx" */),
  "component---src-templates-contenders-tsx": () => import("./../../../src/templates/contenders.tsx" /* webpackChunkName: "component---src-templates-contenders-tsx" */),
  "component---src-templates-miscellany-tsx": () => import("./../../../src/templates/miscellany.tsx" /* webpackChunkName: "component---src-templates-miscellany-tsx" */)
}

